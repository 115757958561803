


































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {CHOICES_BY_VERSION} from './TorontoCHMB41HQuiz2Question2.vue';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'TorontoCHMB41HQuiz2Question3',
  components: {DragToRankInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        order: [] as number[],
      },
    };
  },
  computed: {
    seed(): number {
      return (this.$gate as Gate<PolicyMap>).user?.id ?? 1;
    },
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    imagePrefix(): string {
      return `/img/assignments/UofT/CHMB41H/quiz-2/question-2/q2q2v${this.version}-`;
    },
    choices(): string[] {
      return seededShuffle(Object.values(CHOICES_BY_VERSION[this.version - 1]), this.seed);
    },
    images(): string[] {
      return seededShuffle(
        ['A', 'B', 'C', 'D'].map((l: string) => this.imagePrefix + 'molecule' + l + '.png'),
        this.seed
      );
    },
  },
});
